@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,600;0,700;1,400;1,600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;600&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

.pending {
  background: rgba(0, 93, 103, 0.5);
}

.in-progress {
  background: #f6a326 !important;
}

.verified {
  background: #63d69e !important;
}

.unverifiable {
  background-color: #b01f45;
}

.font-smart-addressing {
  font-size: 2.75rem;
}

@media screen and (min-width: 1025px) {
  .desktop-layout {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) translateZ(0);
    width: 28rem;
    height: 43.75rem; /* 700px for 16px body */
  }
}

@layer base {
  html {
    height: 100vh;
  }
  
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  body {
    font-size: 16px;
    height: 100%;
    width: 100%;
  }
}

@layer components {
  .text-ellipsis {
    @apply overflow-hidden overflow-ellipsis whitespace-nowrap;
  }
}

.linear-loader {
  animation: linear-move 1.3s linear infinite;
}
@keyframes linear-move {
  0% {
    transform: translateX(-100%);
    width: 20%;
  }
  50% {
    width: 50%;
    transform: translateX(0);
  }
  100%  {
    width: 20%;
    transform: translateX(calc(100vw + 100%));
  }
}

.slide-down-top {
  transform: translateY(0);
}
.slide-up-top { 
  transform: translateY(-100%);
}
.slide-down-bottom {
  transform: translateY(100%);
}
.slide-up-bottom { 
  transform: translateY(0);
}

.delay-transition {
  transition-delay: 2s;
}

@keyframes slide-out {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}